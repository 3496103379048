<template>
	<div class="status-tag" :class="displayStatus.toLowerCase()">
		<svg-icon v-if="status === 'Published'" icon="check-circle" fixed style="margin-right: 2px;" />
		{{ displayStatus }}
	</div>
</template>

<script>
export default {
	name: 'StatusTag',
	props: {
		status: {
			type: String,
			required: false,
		},
		isLegacy: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		displayStatus() {
			if (this.isLegacy) return 'Legacy'
			return this.status
		},
	},
}
</script>

<style lang="scss">
.status-tag {
	display: inline-flex;
	align-items: center;
	padding: 2px 6px;
	border-radius: 3px;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	&.legacy {
		background: var(--report-legacy-bg);
		color: var(--report-legacy-text);
	}
	&.published {
		background: var(--report-published-bg);
		color: var(--report-published-text);
	}
	&.retired {
		background: var(--report-retired-bg);
		color: var(--report-retired-text);
	}
	&.draft {
		background: var(--report-draft-bg);
		color: var(--report-draft-text);
	}
}
</style>
