

























import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import state from '@services/reportService'
import { TemplateSet } from '../classes'

const NewReportTemplateDlg = {
	name: 'NewReportDlg',
	props: ['type', 'consultantId'],
	data() {
		return {
			name: '',
			selectedType: '',
			sets: [],
		}
	},
	components: {
		DlgHeader,
		ModalWrapper,
	},
	methods: {
		async create() {
			if (!this.name || !this.name.length) return
			let setid = await state.createSet(this.name, this.selectedType, this.consultantId)
			dlg.close(this, true, setid)
		},
		close() {
			dlg.close(this)
		},
	},
	async created() {
		let meta = await state.getTemplateMeta()
		this.sets = meta.sets
	},
	mounted() {
		this.selectedType = this.type
	},
}

export default NewReportTemplateDlg

export function openNewReportTemplateDlg(
	type: string,
	consultantId: string = null
): Promise<TemplateSet> {
	return dlg.open<TemplateSet>(NewReportTemplateDlg, { type, consultantId })
}
