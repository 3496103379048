























































































































































































































import { mapState } from 'vuex'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import DlgHeader from '@components/DlgHeader.vue'
import AstRadio from '@components/Radio.vue'
import DateRangePicker from '@components/DateRangePicker.vue'
import ModalitySelect from '@components/ModalitySelect.vue'
import { dateRangeLabels, getDateRanges } from '@utils/dateUtils'
import consultations from '@services/consultationService'
import { userData } from '@services/userData'
import { billingCodes } from '@services/billingCodes'
import reportService from '@services/reportService'

class AnalyzeReportsFilter implements ITeleconsultationListSearchForm {
	consultantId = ''
	isComplete = 'Any'
	isDraft = 'Any' // teleconsultation only
	modalityIds = []
	billingCodeId = null
	startDate = '' // request date / report creation date
	endDate = '' // request date / report creation date
	responseStartDate = '' // teleconsultation only
	responseEndDate = '' // teleconsultation only
	studyStartDate = ''
	studyEndDate = ''
	region = '' // teleconsultation only
	requestType = '00000000-0000-0000-0000-000000000000' // teleconsultation only
	claimedByConsultantId = '' // teleconsultation only
	clinicCode = '' // teleconsultation only
	requestingUserId = '' // teleconsultation only (for community users)
}

const AnalyzeReports = {
	name: 'AnalyzeReports',
	components: {
		AstRadio,
		DateRangePicker,
		DlgHeader,
		ModalWrapper,
		ModalitySelect,
	},
	props: {
		initialReportType: {
			type: String,
			default: 'teleconsultation',
		},
	},
	dateRangeOptions: Object.entries(dateRangeLabels)
		.map(([value, label]) => ({ value, label }))
		.filter(({ label }) => !label.includes('Past')), // presets do not use the Past Week/Month/Year ranges
	data() {
		return {
			filter: new AnalyzeReportsFilter(),
			isLoading: false,
			reportType: '',
			consultant: {},
			consultantList: [],
			groupMembers: [],
			regions: [],
			clientClinics: [],
			communityUsers: [],
			requestTypes: [],
			accountBillingCodes: [],
			consultantBillingCodes: [],
      dateRanges: ['Year', 'Week', 'Yesterday', 'Today'],
      hasErrors: false,
      studyDateRangeError: false,
      requestDateRangeError: false,
      responseDateRangeError: false,
		}
	},
	computed: {
		billingCodes() {
			if (this.reportType === 'teleconsultation') {
				return this.consultantBillingCodes
			} else {
				return this.accountBillingCodes
			}
		},
		requestedByOptions() {
			const clinics = this.clientClinics.map(c => ({
				name: c.isCommunity ? 'All Community Users' : c.name,
				clinicCode: c.clinicCode,
				requestingUserId: '',
			}))
			const communityUsers = this.communityUsers.map(c => ({
				name: c.name,
				clinicCode: c.clinicCode,
				requestingUserId: c.id,
			}))
			return [...clinics, ...communityUsers].sort((a, b) => (a.name < b.name ? -1 : 1))
		},
		requestedBy: {
			get() {
				return this.requestedByOptions.find(
					c => this.filter.clinicCode === c.clinicCode && this.filter.requestingUserId === c.requestingUserId
				)
			},
			set(client) {
				this.filter.clinicCode = client ? client.clinicCode : ''
				this.filter.requestingUserId = client ? client.requestingUserId : ''
			},
		},
		...mapState({
			showTeleconsultationScheduleFiltering: (state: any) =>
				state.static.permissions.showTeleconsultationScheduleFiltering,
		}),
	},
	watch: {
		async consultant() {
			this.loadConsultantFilterOptions()
		},
    filter: {
      handler(newVal) {
        // Check Study Date
        this.studyDateRangeError = this.dateRangeIsBiggerThanAYear(newVal.studyStartDate, newVal.studyEndDate)

        // Check Request / Report Date
        this.requestDateRangeError = this.dateRangeIsBiggerThanAYear(newVal.startDate, newVal.endDate)

        // Check Response Date
        this.responseDateRangeError = this.dateRangeIsBiggerThanAYear(newVal.responseStartDate, newVal.responseEndDate)

        this.hasErrors = this.studyDateRangeError || this.requestDateRangeError || this.responseDateRangeError
      },
      deep: true
    },
	},
	async created() {

    const initialDateRages = getDateRanges()
    // Set initial dates ranges to Today
    this.filter.startDate = initialDateRages.Today.startDate
    this.filter.endDate = initialDateRages.Today.endDate
    this.filter.studyStartDate = initialDateRages.Today.startDate
    this.filter.studyEndDate = initialDateRages.Today.endDate
    this.filter.responseStartDate = initialDateRages.Today.startDate
    this.filter.responseEndDate = initialDateRages.Today.endDate

		this.reportType = this.initialReportType
		this.isAdmin = userData.permissions.serverAdministration
		this.isLoading = true
		try {
			await Promise.all([this.loadConsultantList(), this.getAccountBillingCodes()])
		} finally {
			this.isLoading = false
		}
	},
	methods: {
		async loadConsultantList() {
			let list: IConsultantInfo[] = []
			let used = {}

			function addToList(items: IConsultantInfo[]) {
				items.forEach(c => {
					if (c && used[c.id] === undefined) {
						list.push(c)
						used[c.id] = true
					}
				})
			}

			let promises = []
			// consultant groups user belongs to
			promises.push(consultations.getConsultantGroups(userData.claims.userId).then(r => addToList(r)))

			if (this.isAdmin) {
				// local consultants
				addToList(this.$store.state.static.consultantsForAdmin)
			} else {
				// self
				promises.push(consultations.getConsultantInfo(userData.claims.userId).then(r => addToList([r])))
			}

			await Promise.all(promises)

			list.sort((a, b) => {
				if (a.isGroup && !b.isGroup) return -1
				if (b.isGroup && !a.isGroup) return 1
				else {
					if (a.name < b.name) {
						return -1
					}
					if (a.name > b.name) {
						return 1
					}
					return 0
				}
			})

			this.consultantList = list
			this.consultant = list[0]
		},
		async getAccountBillingCodes() {
			const codes = await billingCodes.get(null)
			this.accountBillingCodes = codes.filter(c => !c.deleted)
		},
		async loadConsultantFilterOptions() {
			this.isLoading = true
			this.filter.claimedByConsultantId = ''
			this.filter.clinicCode = ''
			this.filter.requestingUserId = ''
			this.filter.region = ''
			this.filter.requestType = '00000000-0000-0000-0000-000000000000'
			this.filter.billingCodeId = null
			const requests = [
				consultations.getConsultantGroupMembers,
				consultations.getConsultantRegions,
				consultations.getConsultantClientClinics,
				consultations.getConsultantCommunityUsers,
				consultations.getConsultantRequestTypes,
				billingCodes.get,
			].map(m => m(this.consultant.id))
			try {
				const responses = await Promise.all(requests)
				this.groupMembers = [{ id: '00000000-0000-0000-0000-000000000001', name: 'Unclaimed' }, ...responses[0]]
				this.regions = responses[1]
				this.clientClinics = responses[2]
				this.communityUsers = responses[3]
				this.requestTypes = Object.entries(responses[4]).sort((a, b) => {
					return a[1] < b[1] ? -1 : 1
				})
				this.consultantBillingCodes = responses[5].filter(c => !c.deleted)
			} finally {
				this.isLoading = false
			}
		},
		close() {
			dlg.close(this)
		},
		exportCSV() {
			this.filter.consultantId = this.reportType === 'teleconsultation' ? this.consultant.id : ''
			reportService.exportCSV(this.filter)
		},
    dateRangeIsBiggerThanAYear(startDate,endDate) {
      const years = new Date(endDate - startDate).getFullYear() - 1970;
      return years > 1
    }
	},
}

export default AnalyzeReports

export function openAnalyzeReports(initialReportType) {
	return dlg.open(AnalyzeReports, { initialReportType })
}
