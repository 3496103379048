






























import StatusTag from './StatusTag.vue'
import { TemplateSetStatus } from '@reporting/classes'
import state from '@services/reportService'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'

export default {
	name: 'TemplateSet',
	components: {
		StatusTag,
	},
	props: ['set', 'sets'],
	data() {
		return {
			state,
		}
	},
	computed: {
		isActive() {
			return this.set.status === TemplateSetStatus.Published
		},
		isTeleconsultation() {
			return this.set.type === 'Teleconsultation'
		},
		retired() {
			return this.set.status === TemplateSetStatus.Retired
		},
		draft() {
			return this.set.status === TemplateSetStatus.Draft
		},
	},
	methods: {
		load() {
			this.$router.push({
				name: 'report-editor',
				params: { setId: this.set.id },
			})
		},
		async retire() {
			if (this.isTeleconsultation) return
			if (!(await showConfirm('Are you sure you want to retire this template set?'))) return
			await state.setTemplateSetStatus(this.set, TemplateSetStatus.Retired)
		},
		deleteWorkflow() {
			this.$emit('delete-template-set', this.set)
		},
	},
}
